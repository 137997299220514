import { defineStore } from 'pinia'
export const useMainStore = defineStore('_pixtame_main', {
  state: () => ({
    isRequestLoadingBool: false,
    isSidebarOpenedBool: false,
    isScanningBool: false
  }),
  getters: {
    isRequestLoading: state => {
      return state.isRequestLoadingBool
    },
    isSidebarOpened: state => {
      return state.isSidebarOpenedBool
    },
    isScanning: state => {
      return state.isScanningBool
    },
  },
  actions: {
    requestLoading (boolVal) {
      this.isRequestLoadingBool = boolVal
    },
    sidebarOpened (boolVal) {
      this.isSidebarOpenedBool = boolVal
    },
    scanningChange (boolVal) {
      this.isScanningBool = boolVal
    }
  },
  persist: true,
  // modules: {
    // user
  // },
  // plugins: [createPersistedState({
  //   key: 'pixta',
  //   paths: ['user']
  // })]
})
