
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as betaFlYGrNNsiagoMZdpRBDaUVwjCfOvanMdLXnsKIiEPmsMeta } from "/vercel/path0/pages/beta.vue?macro=true";
import { default as busca9nYP5cCcWFyS4pfD_454tt2_459vOXqcNAkJ1RDnOvdNIQsMeta } from "/vercel/path0/pages/busca.vue?macro=true";
import { default as contafTRvmV_457rm7qztKmbr3eNvhdf16aFhNNN0d9JUlHHCEMeta } from "/vercel/path0/pages/conta.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as entrarHW_45HQkdWUC54vvS1wsWsiXld5t5HJX_45tPIhjgnDSG2QMeta } from "/vercel/path0/pages/entrar.vue?macro=true";
import { default as arquivoz8QD5d9Bfp8xp8jTedFe_45N6rjPPDoPPU8TyIRNOLRVMMeta } from "/vercel/path0/pages/arquivo.vue?macro=true";
import { default as cidadesZc4Zw230BZMjVWCnAuHGMagcOICpBiBhom79vo05Li4Meta } from "/vercel/path0/pages/cidades.vue?macro=true";
import { default as indexHV10gBjlMV_ztkz_45I3Hi_KGuLDaCrGZwoVS5QWNChIIMeta } from "/vercel/path0/pages/carteira/index.vue?macro=true";
import { default as extrato4yYsXQUXQs1OjrjnRoTTpXtbWjhJMGrO9_45ORHB3B6vEMeta } from "/vercel/path0/pages/carteira/extrato.vue?macro=true";
import { default as carteiraXGnpzbAZ6UQwoKbmSP2X2rev_wdsCvwtUc7rh47Lh_MMeta } from "/vercel/path0/pages/carteira.vue?macro=true";
import { default as oldindexsNTOgXjDuMrGBZ6gnDmQhmfPuy_dfY7vqzBVuYTs3AoMeta } from "/vercel/path0/pages/oldindex.vue?macro=true";
import { default as resgatarY4BJmZZsuTLdpEbLqzgEgYFRHHOGvwIs5pkS2Sfp4NcMeta } from "/vercel/path0/pages/resgatar.vue?macro=true";
import { default as my_accounti4_8qKzz2wG0OV8jax8H_456OTJgdW0Zi1xzrI8wyDSOIMeta } from "/vercel/path0/pages/my_account.vue?macro=true";
import { default as indexWogUlT05cqoTyBMQdLmQHRY9fb_45_k4G7dDiONuYU7JoMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as como_45funciona_45kiLCiRXuxuNsNZpU8RNvJ_8jd_45Jg86QQDGblzE1p_45AMeta } from "/vercel/path0/pages/como-funciona.vue?macro=true";
import { default as editScdT_FZtNzJEBP4lTJBEKKVHMUtnbE7FHq2GIq0EYu4Meta } from "/vercel/path0/pages/p/[slug]/edit.vue?macro=true";
import { default as indexHi5cowKRZn6Pv99ADvqbzu3mHO0m6rWzHdG_3QJBwv8Meta } from "/vercel/path0/pages/p/[slug]/index.vue?macro=true";
import { default as indexsawJIh63Ks9rM2n2JL_KBeExU5WeKDVp9cD5sh0HH8IMeta } from "/vercel/path0/pages/u/[slug]/index.vue?macro=true";
import { default as crie_45seu_45eventoRUnbs3barb_45tdmvra9QJcgzwyj5Fv2qrBEqKnAfoghgMeta } from "/vercel/path0/pages/crie-seu-evento.vue?macro=true";
import { default as index4_6nA9UDWfg_NrqEqs6Orx3acM_453pAVBLj_DO7xH3SAMeta } from "/vercel/path0/pages/ingressos/index.vue?macro=true";
import { default as indexjVwKRZMLJci9iV8Wxc8TvtPf8D_4596ND40_qEv9nwZCcMeta } from "/vercel/path0/pages/parceiros/index.vue?macro=true";
import { default as indexsUfKxnIDAgPxc9BaCl9TGK7RNdmwg2X_by29rzk6Xx4Meta } from "/vercel/path0/pages/onboarding/index.vue?macro=true";
import { default as indexEHhNDgypPLkOOT3sicfEv1YRyr8QJ7oybjHMr_enEvkMeta } from "/vercel/path0/pages/parceiros/[slug]/index.vue?macro=true";
import { default as galeriaEBYWCO9fH6_NLJfnKDLX_45OB4jaKdMMh4H4F5kbGBLbYMeta } from "/vercel/path0/pages/parceiros/[slug]/galeria.vue?macro=true";
import { default as rankingYEa_rlWjBaLAB2dJ3IuXQriNh_456pPZdKsajTvoiQ6aEMeta } from "/vercel/path0/pages/parceiros/[slug]/ranking.vue?macro=true";
import { default as _91slug_93MalkTI2MS9y8ffPC5BEa_Zn69qZvotlcRpbMtj8o8KMMeta } from "/vercel/path0/pages/parceiros/[slug].vue?macro=true";
import { default as reducao_45de_45danosmFaGPk2bMg23fO4pSxjIUSWe9HUAlJjNFmYDUKVT8hsMeta } from "/vercel/path0/pages/reducao-de-danos.vue?macro=true";
import { default as indexzaj6vobQSS50lQPXphqBfSmMIDDfJ8ALo9dP2gxRMp4Meta } from "/vercel/path0/pages/orders/[id]/index.vue?macro=true";
import { default as passadosMRRJM4lX95DXw9LFAhCJbIicf0SqBI6DLKGgv__452vIMMeta } from "/vercel/path0/pages/ingressos/passados.vue?macro=true";
import { default as newXS3kaA1_VK7HPucuYpy9bzCkyTgcd0Btj6_JKJA4o7UMeta } from "/vercel/path0/pages/p/[slug]/campaigns/new.vue?macro=true";
import { default as index8WifL_ueT0G_459vib61lykGMcFTq050_5PFut3A_OvwkMeta } from "/vercel/path0/pages/p/[slug]/campaigns/index.vue?macro=true";
import { default as editLLKjeS6YIWi9h4N415qUm_4uf2P5XZA2ShZT7R7002sMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/edit.vue?macro=true";
import { default as sendl3OJyp2lmYWCPG9LESUsJZz9yktprnfwJdvPPF_hlcUMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/send.vue?macro=true";
import { default as index_45dzoLKO_EL_45gqv6B3IAyJ_45wl35r_45zKYkKgqVnXEZ_iUMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/index.vue?macro=true";
import { default as campaignsjnqFjV3Z4x_45LHms_45GMjVcV0tHc_x_45IRjw8QZt0yvlSMMeta } from "/vercel/path0/pages/p/[slug]/campaigns.vue?macro=true";
import { default as newkUViaTSwQsUqUf9Ol7WppInmDXIyNs4v0tEz6ddwqRkMeta } from "/vercel/path0/pages/p/[slug]/guestlists/new.vue?macro=true";
import { default as indexvrPdwnq5ZU4NzEcqeTCiWtfWsrgfGJXpoFrUrkUDLgEMeta } from "/vercel/path0/pages/p/[slug]/guestlists/index.vue?macro=true";
import { default as editTlXFUUGMlFm3uvSlYgpKI5U1AtsOLy9Xf0QQ0D5x4K8Meta } from "/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/edit.vue?macro=true";
import { default as indexDhz_gh0VAvaV_VtTgT5t5DO45zzIIc08p1hGlP6PnoAMeta } from "/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/index.vue?macro=true";
import { default as guestlistsZ1r5qVLoJ4RCyAsruAA4WtzX5pwNLMQQfN5n6F8YSbsMeta } from "/vercel/path0/pages/p/[slug]/guestlists.vue?macro=true";
import { default as newe4gd3rK3yx6dROSjo6y_3PTfchatlUKYMeeEjFi7QegMeta } from "/vercel/path0/pages/producer_wizard/new.vue?macro=true";
import { default as indexhBERT1qDXj6HTJG6h9q1juabi7eShKk6qJnWMIwIkiQMeta } from "/vercel/path0/pages/ingressos/[id]/index.vue?macro=true";
import { default as cancelarwRXSPe4O_hkjTFd90qxypeBtf9DjXsHKNNcTDYN1YqQMeta } from "/vercel/path0/pages/orders/[id]/cancelar.vue?macro=true";
import { default as index_ZdSQR8IgJtyBIrs4LAauy2CERBA3HYf4K70lTrBns0Meta } from "/vercel/path0/pages/producer_wizard/index.vue?macro=true";
import { default as indexjUy7Cjy7Phyvbq3tfFnfv57vuBYXyJsZqmkvLnmTBqMMeta } from "/vercel/path0/pages/central-de-ajuda/index.vue?macro=true";
import { default as pixYwRtqbJYVPI6U3xLWOZWZc6whdrdQRExBfHE7b1_uSgMeta } from "/vercel/path0/pages/checkout/[orderId]/pix.vue?macro=true";
import { default as _91_91tag_93_93rux7xI9RaMHGmjvsB4Sg5aPwGiP4F20q_45hTS6xG3CD4Meta } from "/vercel/path0/pages/eventos/[city]/[[tag]].vue?macro=true";
import { default as card1GBVnr85vgS0ZohaTcEVB_45iS73RYRL7fksWQdHtaPOcMeta } from "/vercel/path0/pages/checkout/[orderId]/card.vue?macro=true";
import { default as transferirlwZlg9TH8X_E26lzb2F_45emVF0TCSMmrigBLDnq_45EsKMMeta } from "/vercel/path0/pages/ingressos/[id]/transferir.vue?macro=true";
import { default as trocar_45nomePbzDzLzt3XBrTK0fBvjonSUnm8y5TB8tj3tMbke4UCsMeta } from "/vercel/path0/pages/ingressos/[id]/trocar-nome.vue?macro=true";
import { default as indexHH82TDbf8VM7AdUOa66cXHYxnwXLgKxa4J9OS84WFKgMeta } from "/vercel/path0/pages/u/[slug]/[productSlug]/index.vue?macro=true";
import { default as _91guestlistId_93s6XGHGxSrosZjogXYLxsHqxoe9j_45x9NUlMrRQ23Se9MMeta } from "/vercel/path0/pages/p/[slug]/listas/[guestlistId].vue?macro=true";
import { default as _91slug_93VWeWOzK8ZS_452poxWQn1mJVbM0B_45gKXEXT6YM5bR_45Ib4Meta } from "/vercel/path0/pages/producer_wizard/success/[slug].vue?macro=true";
export default [
  {
    name: "beta",
    path: "/beta",
    component: () => import("/vercel/path0/pages/beta.vue")
  },
  {
    name: "busca",
    path: "/busca",
    component: () => import("/vercel/path0/pages/busca.vue")
  },
  {
    name: "conta",
    path: "/conta",
    component: () => import("/vercel/path0/pages/conta.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "entrar",
    path: "/entrar",
    component: () => import("/vercel/path0/pages/entrar.vue")
  },
  {
    name: "arquivo",
    path: "/arquivo",
    component: () => import("/vercel/path0/pages/arquivo.vue")
  },
  {
    name: "cidades",
    path: "/cidades",
    component: () => import("/vercel/path0/pages/cidades.vue")
  },
  {
    name: carteiraXGnpzbAZ6UQwoKbmSP2X2rev_wdsCvwtUc7rh47Lh_MMeta?.name,
    path: "/carteira",
    component: () => import("/vercel/path0/pages/carteira.vue"),
    children: [
  {
    name: "carteira",
    path: "",
    component: () => import("/vercel/path0/pages/carteira/index.vue")
  },
  {
    name: "carteira-extrato",
    path: "extrato",
    component: () => import("/vercel/path0/pages/carteira/extrato.vue")
  }
]
  },
  {
    name: "oldindex",
    path: "/oldindex",
    component: () => import("/vercel/path0/pages/oldindex.vue")
  },
  {
    name: "resgatar",
    path: "/resgatar",
    component: () => import("/vercel/path0/pages/resgatar.vue")
  },
  {
    name: "my_account",
    path: "/my_account",
    component: () => import("/vercel/path0/pages/my_account.vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/pages/orders/index.vue")
  },
  {
    name: "como-funciona",
    path: "/como-funciona",
    component: () => import("/vercel/path0/pages/como-funciona.vue")
  },
  {
    name: "p-slug-edit",
    path: "/p/:slug()/edit",
    meta: editScdT_FZtNzJEBP4lTJBEKKVHMUtnbE7FHq2GIq0EYu4Meta || {},
    component: () => import("/vercel/path0/pages/p/[slug]/edit.vue")
  },
  {
    name: "p-slug",
    path: "/p/:slug()",
    component: () => import("/vercel/path0/pages/p/[slug]/index.vue")
  },
  {
    name: "u-slug",
    path: "/u/:slug()",
    component: () => import("/vercel/path0/pages/u/[slug]/index.vue")
  },
  {
    name: "crie-seu-evento",
    path: "/crie-seu-evento",
    component: () => import("/vercel/path0/pages/crie-seu-evento.vue")
  },
  {
    name: "ingressos",
    path: "/ingressos",
    component: () => import("/vercel/path0/pages/ingressos/index.vue")
  },
  {
    name: "parceiros",
    path: "/parceiros",
    component: () => import("/vercel/path0/pages/parceiros/index.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue")
  },
  {
    name: _91slug_93MalkTI2MS9y8ffPC5BEa_Zn69qZvotlcRpbMtj8o8KMMeta?.name,
    path: "/parceiros/:slug()",
    component: () => import("/vercel/path0/pages/parceiros/[slug].vue"),
    children: [
  {
    name: "parceiros-slug",
    path: "",
    component: () => import("/vercel/path0/pages/parceiros/[slug]/index.vue")
  },
  {
    name: "parceiros-slug-galeria",
    path: "galeria",
    component: () => import("/vercel/path0/pages/parceiros/[slug]/galeria.vue")
  },
  {
    name: "parceiros-slug-ranking",
    path: "ranking",
    component: () => import("/vercel/path0/pages/parceiros/[slug]/ranking.vue")
  }
]
  },
  {
    name: "reducao-de-danos",
    path: "/reducao-de-danos",
    component: () => import("/vercel/path0/pages/reducao-de-danos.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/vercel/path0/pages/orders/[id]/index.vue")
  },
  {
    name: "ingressos-passados",
    path: "/ingressos/passados",
    component: () => import("/vercel/path0/pages/ingressos/passados.vue")
  },
  {
    name: campaignsjnqFjV3Z4x_45LHms_45GMjVcV0tHc_x_45IRjw8QZt0yvlSMMeta?.name,
    path: "/p/:slug()/campaigns",
    meta: campaignsjnqFjV3Z4x_45LHms_45GMjVcV0tHc_x_45IRjw8QZt0yvlSMMeta || {},
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns.vue"),
    children: [
  {
    name: "p-slug-campaigns-new",
    path: "new",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/new.vue")
  },
  {
    name: "p-slug-campaigns",
    path: "",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/index.vue")
  },
  {
    name: "p-slug-campaigns-campaignId-edit",
    path: ":campaignId()/edit",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/edit.vue")
  },
  {
    name: "p-slug-campaigns-campaignId-send",
    path: ":campaignId()/send",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/send.vue")
  },
  {
    name: "p-slug-campaigns-campaignId",
    path: ":campaignId()",
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/index.vue")
  }
]
  },
  {
    name: guestlistsZ1r5qVLoJ4RCyAsruAA4WtzX5pwNLMQQfN5n6F8YSbsMeta?.name,
    path: "/p/:slug()/guestlists",
    meta: guestlistsZ1r5qVLoJ4RCyAsruAA4WtzX5pwNLMQQfN5n6F8YSbsMeta || {},
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists.vue"),
    children: [
  {
    name: "p-slug-guestlists-new",
    path: "new",
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/new.vue")
  },
  {
    name: "p-slug-guestlists",
    path: "",
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/index.vue")
  },
  {
    name: "p-slug-guestlists-guestlistId-edit",
    path: ":guestlistId()/edit",
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/edit.vue")
  },
  {
    name: "p-slug-guestlists-guestlistId",
    path: ":guestlistId()",
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/index.vue")
  }
]
  },
  {
    name: "producer_wizard-new",
    path: "/producer_wizard/new",
    component: () => import("/vercel/path0/pages/producer_wizard/new.vue")
  },
  {
    name: "ingressos-id",
    path: "/ingressos/:id()",
    component: () => import("/vercel/path0/pages/ingressos/[id]/index.vue")
  },
  {
    name: "orders-id-cancelar",
    path: "/orders/:id()/cancelar",
    component: () => import("/vercel/path0/pages/orders/[id]/cancelar.vue")
  },
  {
    name: "producer_wizard",
    path: "/producer_wizard",
    component: () => import("/vercel/path0/pages/producer_wizard/index.vue")
  },
  {
    name: "central-de-ajuda",
    path: "/central-de-ajuda",
    component: () => import("/vercel/path0/pages/central-de-ajuda/index.vue")
  },
  {
    name: "checkout-orderId-pix",
    path: "/checkout/:orderId()/pix",
    component: () => import("/vercel/path0/pages/checkout/[orderId]/pix.vue")
  },
  {
    name: "eventos-city-tag",
    path: "/eventos/:city()/:tag?",
    component: () => import("/vercel/path0/pages/eventos/[city]/[[tag]].vue")
  },
  {
    name: "checkout-orderId-card",
    path: "/checkout/:orderId()/card",
    component: () => import("/vercel/path0/pages/checkout/[orderId]/card.vue")
  },
  {
    name: "ingressos-id-transferir",
    path: "/ingressos/:id()/transferir",
    component: () => import("/vercel/path0/pages/ingressos/[id]/transferir.vue")
  },
  {
    name: "ingressos-id-trocar-nome",
    path: "/ingressos/:id()/trocar-nome",
    component: () => import("/vercel/path0/pages/ingressos/[id]/trocar-nome.vue")
  },
  {
    name: "u-slug-productSlug",
    path: "/u/:slug()/:productSlug()",
    component: () => import("/vercel/path0/pages/u/[slug]/[productSlug]/index.vue")
  },
  {
    name: "p-slug-listas-guestlistId",
    path: "/p/:slug()/listas/:guestlistId()",
    component: () => import("/vercel/path0/pages/p/[slug]/listas/[guestlistId].vue")
  },
  {
    name: "producer_wizard-success-slug",
    path: "/producer_wizard/success/:slug()",
    component: () => import("/vercel/path0/pages/producer_wizard/success/[slug].vue")
  }
]