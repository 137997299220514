import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_ZOcCMJEIEaKylqt_uPmYt7U1DE1JQA23jSbUIkuFkXo from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.81_db0@0.3.1_ioredis@5.6.0_lightningcss@1_rvfazzlxhujnsnluh3jmxa6j7u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead__XUai8TPN8b6khiujV9OjgWYfgih0MXA1xI1Xg7m7xY from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.81_db0@0.3.1_ioredis@5.6.0_lightningcss@1_rvfazzlxhujnsnluh3jmxa6j7u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xrujM5nTgifl0xgExjHpWRQM63ah9lXCBOLhLJrKcu4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.81_db0@0.3.1_ioredis@5.6.0_lightningcss@1_rvfazzlxhujnsnluh3jmxa6j7u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client__dyrkEHHIvEpK6BRoyXo9avx5ZlEYN47UmfRlFD0D_Q from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.81_db0@0.3.1_ioredis@5.6.0_lightningcss@1_rvfazzlxhujnsnluh3jmxa6j7u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client__vGXt5VnN81rrgkFbDaZ4cvevkoRHxRrindunFL5Nmg from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.81_db0@0.3.1_ioredis@5.6.0_lightningcss@1_rvfazzlxhujnsnluh3jmxa6j7u/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_p7moa_AwOm3QJ7BAF9V3VIY0t8DGLr9bKMpFcfTiWiE from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.81_db0@0.3.1_ioredis@5.6.0_lightningcss@1_rvfazzlxhujnsnluh3jmxa6j7u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3XWNhBAwjQi10bjnx0b5HWG_cH8nea1svCPJ78c8AO0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.81_db0@0.3.1_ioredis@5.6.0_lightningcss@1_rvfazzlxhujnsnluh3jmxa6j7u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_vakGyWE_Cyo7SG02CJjxXL3y995it3gH_SYwmIPTyeE from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@18.19.81_db0@0.3.1_ioredis@5.6.0_lightningcss@1_rvfazzlxhujnsnluh3jmxa6j7u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0 from "/vercel/path0/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_vm51vbQbgKCG3tkZ6Rd3HIAmbTmGVpUnCbrTRabayDs from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5._kqpujbz6xdy7oo26diqtjfru6e/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_BBfe7L0Kil3VwImMOeYPwUSt_0IvckyPnsbHEJoWn_U from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@1.2.7_magicast@0.3.5/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_JXqV8VanYI5eTlKKysKchj3KoLqX0Gx_iWGour8tGRE from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node_iolqf5b4ll5rdx5kvg3nb2hgqi/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import ahoy_client_nd_hct2chExINaAqnvMeJpoQOVjyp0gYXZxY3z_t8yc from "/vercel/path0/plugins/ahoy.client.js";
import filters_ngmmm_RplZsituyWtz7jnlrIx1AzWAzoFi_5TfN2fiA from "/vercel/path0/plugins/filters.js";
import maska_B9RWEzmoqLJEP7bcCfdU3BGJMkQOGxVR8hqObQF5VAs from "/vercel/path0/plugins/maska.js";
import posthog_client_k_93ax9wvjMk273QaphCGslBGMEGAt2ipswJ9W8luZA from "/vercel/path0/plugins/posthog.client.js";
import vue3_toastify_tU4V_Q_3gRw3rPPdj6Y_FGU4jrEWuLb9DIUytSnZjGI from "/vercel/path0/plugins/vue3-toastify.ts";
import vuedatepicker_nfBAffa4rArornbzIcR94pKiweEjPFr1tOYCv6ZDyQs from "/vercel/path0/plugins/vuedatepicker.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_ZOcCMJEIEaKylqt_uPmYt7U1DE1JQA23jSbUIkuFkXo,
  unhead__XUai8TPN8b6khiujV9OjgWYfgih0MXA1xI1Xg7m7xY,
  router_xrujM5nTgifl0xgExjHpWRQM63ah9lXCBOLhLJrKcu4,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client__dyrkEHHIvEpK6BRoyXo9avx5ZlEYN47UmfRlFD0D_Q,
  navigation_repaint_client__vGXt5VnN81rrgkFbDaZ4cvevkoRHxRrindunFL5Nmg,
  check_outdated_build_client_p7moa_AwOm3QJ7BAF9V3VIY0t8DGLr9bKMpFcfTiWiE,
  chunk_reload_client_3XWNhBAwjQi10bjnx0b5HWG_cH8nea1svCPJ78c8AO0,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_vakGyWE_Cyo7SG02CJjxXL3y995it3gH_SYwmIPTyeE,
  nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0,
  plugin_vm51vbQbgKCG3tkZ6Rd3HIAmbTmGVpUnCbrTRabayDs,
  plugin_BBfe7L0Kil3VwImMOeYPwUSt_0IvckyPnsbHEJoWn_U,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_JXqV8VanYI5eTlKKysKchj3KoLqX0Gx_iWGour8tGRE,
  ahoy_client_nd_hct2chExINaAqnvMeJpoQOVjyp0gYXZxY3z_t8yc,
  filters_ngmmm_RplZsituyWtz7jnlrIx1AzWAzoFi_5TfN2fiA,
  maska_B9RWEzmoqLJEP7bcCfdU3BGJMkQOGxVR8hqObQF5VAs,
  posthog_client_k_93ax9wvjMk273QaphCGslBGMEGAt2ipswJ9W8luZA,
  vue3_toastify_tU4V_Q_3gRw3rPPdj6Y_FGU4jrEWuLb9DIUytSnZjGI,
  vuedatepicker_nfBAffa4rArornbzIcR94pKiweEjPFr1tOYCv6ZDyQs
]