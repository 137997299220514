import UserService from '@/services/user.service.js'
import ManagerProducerService from '@/services/manager/producer.service.js'

import { defineStore } from 'pinia'

export const useUserStore = defineStore('_pixtame_user', {
  namespaced: false,
  persist: true,
  state: () => ({
    isLoggedIn: false,
    user: {},
    currentCitySelected: {
      id: null,
      name: null,
      uf: null
    },
    producer: {
      id: null,
      name: null
    },
    redirect: null
  }),
  getters: {
    loggedIn: state => {
      return state.isLoggedIn
    },
    currentCity: state => {
      return state.currentCitySelected
    },
    currentUser: state => {
      return state.user
    },
    currentProducer: state => {
      return state.producer
    },
    isProducer: state => {
      try {
        return state.producer.id != 'null' && state.producer.id != null
      } catch (_) {
        return false
      }
    },
    token: state => {
      return state.user && state.user.token ? state.user.token : null
    },
    redirectAfterLogin: state => {
      return state.redirect
    },
    cableConnectionUrl: state => {
      try {
        const token = state.user.token.replace('Bearer ', '')
        return `${import.meta.env.VITE_APP_CABLE_URL}cable?stoken=${token}`
      } catch (_) {
        return `${import.meta.env.VITE_APP_CABLE_URL}cable`
      }
    }
  },
  actions: {
    async updateCurrentUser () {
      // const currentToken = state.user.token
      const currentToken = this.user.token
      const userResult = await UserService.account()
      this.user = userResult.data
      this.user.token = currentToken
    },

    async updateCurrentProducer (slug = null) {
      if(slug) {
        const producerResult = await ManagerProducerService.find(slug)
        this.producer = producerResult.data
      } else {
        this.producer = {}
      }
    },

    saveRedirectAfterLogin (redirect) {
      this.redirect = redirect
    },

    clearRedirectAfterLogin () {
      this.redirect = null
    },

    logout () {
      if (!import.meta.env.SSR) {
        import ('ahoy.js').then(a => {
          const ahoy = a.default

          ahoy.reset()
          ahoy.configure({
            headers: {
              "Authorization": null
            },
          });
        })
      }
      this.isLoggedIn = false
      this.user = null
    },
    changeCity (cityObject) {
      this.currentCitySelected = cityObject
    },
    login (user) {
      this.isLoggedIn = true
      this.user = user.data

      try {
        if (!import.meta.env.SSR) {
          import ('ahoy.js').then(a => {
            const ahoy = a.default
            ahoy.configure({
              headers: {
                "Authorization": user.headers.authorization
              },
            });
          })
        }
      } catch(_) {
        console.log(`error on configuring ahoy`, _)
      }

      if (user.headers && user.headers.authorization) {
        this.user.token = user.headers.authorization
      }

    },
  },
})
