// import { defineNuxtPlugin } from '#app'
import posthog from 'posthog-js'

export default defineNuxtPlugin(nuxtApp => {
  const runtimeConfig = useRuntimeConfig();
  const posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
    api_host: runtimeConfig.public.posthogHost || 'https://app.posthog.com',
    loaded: (posthog) => {
      // if (import.meta.env.MODE === 'development') posthog.debug();
      if (process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true') {
        posthog.debug(true)
      } else if (localStorage.getItem('ph_debug')) {
        posthog.debug(false)
      }
    },
  })

  // Make sure that pageviews are captured with each route change
  const router = useRouter();
  router.afterEach((to) => {
    posthog.capture('$pageview', {
      current_url: to.fullPath
    });
  });

  return {
    provide: {
      posthog: () => posthogClient
    }
  }
})
